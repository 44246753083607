import 'bootstrap';
import $ from "jquery";

$(function() {
    $('.carousel').carousel();

    if(document.cookie.split(';').filter(function(item) {
        return item.indexOf('accept=true') >= 0
    }).length) {
        $('.cookies').css('display', 'none');
    } else {
        $('.close').click(function() {
            document.cookie = "accept=true; expires=Tue, 01 Jan 2030 07:00:00 GMT; path=/";
        });
    }
    
    $(document).scroll(function() {
        if ($(this).scrollTop() > $('.navbar').height()) {
            $('.navbar').addClass('shadow');
        } else {
            $('.navbar').removeClass('shadow');
        }
    });

    $(document).ready(function() {
        var table = $('table.report').DataTable({
            columnDefs: [{
                orderable: false,
                targets: [3,4,5]
            }]
        });

        $('button#btnGenerate').click( function() {
            var form = $('form[name="report"]').find('input, textarea, select');
            var data = table.$('input, textarea, select').add(form).serialize();
            var $button = $(this);

            $button.text('Proszę czekać...');

            $.ajax({
                url: "/panel/report/add",
                type: 'post',
                data: data,
                success: function (data) {
                    if (data === true) {
                        window.location.href = "/panel/reports";
                    } else {
                        alert('Wystąpił błąd podczas zapisu raportu.');
                    }
                }
            });

            return false;
        } );

        $('button#btnEdit').click( function() {
            var form = $('form[name="report"]').find('input, textarea, select');
            var data = table.$('input, textarea, select').add(form).serialize();
            var id = $('#btnEdit').data('id');
            var $button = $(this);

            $button.text('Proszę czekać...');

            $.ajax({
                url: "/panel/edytuj-raport/"+id,
                type: 'post',
                data: data,
                success: function (data) {
                    if (data === true) {
                        window.location.href = "/panel/reports";
                    } else {
                        alert('Wystąpił błąd podczas zapisu raportu.');
                    }
                }
            });

            return false;
        } );
    } );
});